import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { authTokenVariable } from 'src/app/common/data/constants/local-storage-variables';

@Injectable({
  providedIn: 'root'
})
export class BaseGuard {

  constructor(
    private router: Router
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    if (!sessionStorage.getItem(authTokenVariable)) {
      return true;
    } else {
      this.router.navigate(['dashboard'])
      return false;
    }
  }
  
}
