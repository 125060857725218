<div class="spinner-container position-fixed d-flex justify-content-center align-items-center" style="top: 0; bottom: 0; left: 0; right: 0; z-index: 9999; background-color: white; border-radius: 0px">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style="width: 150px" *ngIf="useSpinnerinass">
    <defs>
      <style>
        .cls-1 {
          fill: #ff0000; /* Cambiar a rojo */
        }
      </style>
    </defs>

    <!-- Barras de puntos circulares -->
    <circle class="cls-1 scaleAnimation1" cx="15" cy="50" r="5">
      <animate attributeName="opacity" values="1;0" dur="1s" begin="0s" repeatCount="indefinite" />
    </circle>
    <circle class="cls-1 scaleAnimation2" cx="35" cy="50" r="5">
      <animate attributeName="opacity" values="1;0" dur="1s" begin="0.2s" repeatCount="indefinite" />
    </circle>
    <circle class="cls-1 scaleAnimation3" cx="55" cy="50" r="5">
      <animate attributeName="opacity" values="1;0" dur="1s" begin="0.4s" repeatCount="indefinite" />
    </circle>
    <circle class="cls-1 scaleAnimation4" cx="75" cy="50" r="5">
      <animate attributeName="opacity" values="1;0" dur="1s" begin="0.6s" repeatCount="indefinite" />
    </circle>
  </svg>
</div>
