<div class="p-3 w-100 d-flex flex-column justify-content-center align-items-center card-border position-relative text-center">
  <!-- Contenedor de la imagen -->
  <div class="mx auto">
    <!-- Contenedor del mansaje -->
    <div class="w-100 mt-2 text-center">
      <!-- Mensaje del cover -->
      <h3 class="ccng-desc ccng-light">{{ messageCover }}</h3>
    </div>
  </div>

  <!-- Contenedor del botón del cover -->
  <div class="ccng-light m-0 mt-3" *ngIf="elementButton && elementButton.show">
    <!-- Botón del cover -->
    <button mat-flat-button class="ccng-bold ccng-bgdefault" style="color: white" (click)="emitAction()">
      <!-- Contenedor de texto del botón -->
      <div class="d-flex align-items-center justify-content-between">
        <!-- Botón del texto -->
        <p class="ms-1 m-0 text-center">{{ elementButton.text }}</p>

        <!-- Link del botón -->
        <span class="ccng-bold" style="cursor: pointer">{{ elementButton.textLink }}</span>
      </div>
    </button>
  </div>
</div>
