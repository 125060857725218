<!-- Versión nueva del input -->
<mat-form-field *ngIf="implementCustomInput" [subscriptSizing]="subscriptSizing" class="col-12 input-size" appearance="outline">

  <!-- Label -->
  <mat-label>{{label}}</mat-label>

  <!-- Input -->
  <input matInput [placeholder]="placeholder" [(ngModel)]="currentValue" [type]="hidePassword ? 'password' : 'text'"
    [required]="required" [errorStateMatcher]="errorMatcher()" [disabled]="disabled" [readonly]="readOnly"
    [maxlength]="maxLength" (blur)="onTouch()" (input)="emitValue()" />

  <!-- Ícono de ojo para la contraseña -->
  <mat-icon class="cursor-pointer" matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility' :
    'visibility_off'}}</mat-icon>

  <!-- Mensajes de error del input -->

  <!-- Required -->
  <mat-error *ngIf="control?.hasError('required')">
    {{ CmmErrorMessagesObject.required }}
  </mat-error>

  <!-- Minlength -->
  <mat-error
    *ngIf="control?.hasError('minlength') &&!control?.hasError('required') && !control?.hasError('pattern') && !showAssistanceMessages">
    {{ CmmErrorMessagesObject.minLength }}
  </mat-error>

  <!-- Mensaje de error de tipo pattern -->
  <mat-error
    *ngIf="control?.hasError('pattern') && !control?.hasError('required') && !control?.hasError('maxlength') && !showAssistanceMessages">
    {{ CmmErrorMessagesObject.incorrectPattern }}
  </mat-error>

  <!-- Maxlength -->
  <mat-error *ngIf="control?.hasError('maxlength') && !showAssistanceMessages">
    {{ CmmErrorMessagesObject.maxLength }}
  </mat-error>

  <!-- Mensaje de error para que coincidan los valores de contraseña -->
  <mat-error *ngIf="control?.hasError('mustMatch') && !showAssistanceMessages">
    {{ CmmErrorMessagesObject.matchPassword }}
  </mat-error>

  <!-- Mensaje de error del API -->
  <mat-error *ngIf="control?.errors?.['apiError']">
    {{ control.errors?.['message']}}
  </mat-error>

</mat-form-field>

<!-- Mensajes de ayuda para poner la contraseña -->
<div [@inOutAnimation] *ngIf="implementCustomInput && showAssistanceMessages && control?.touched"
  class="d-flex flex-column mb-3">

  <span class="fs-12 m-0 d-flex align-items-center "
    [ngClass]="control && !control.hasError(item.error)?'cc-txt-primary':'cc-title-txt'"
    *ngFor="let item of validatorMessages">

    <!-- Ícono de check -->
    <mat-icon class="me-1" aria-hidden="false" aria-label="check validation msg">check_circle</mat-icon>

    <!-- Mensaje de ayuda -->
    {{item.message}}
  </span>

</div>
