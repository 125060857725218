import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { CmmDataService } from 'src/app/common/services/data.service';
import { CmmDialogService } from 'src/app/common/services/dialogs.service';
import { environment } from 'src/environments/environment';
import { Subject, Observable, takeUntil } from 'rxjs';
import { CmmTimerSessionService } from 'src/app/common/services/timer-session.service';
import { cmmActions, cmmSideNav } from 'src/app/common/data/privileges/reducer/privileges.selectos';
import { CmmActionListModel, CmmSideNavModel } from 'src/app/common/data/privileges/models/privileges.models';
import { BaseService } from '../services/base.service';
import { setActionList } from 'src/app/common/data/privileges/reducer/privileges.actions';
import { authTokenVariable } from 'src/app/common/data/constants/local-storage-variables';
import { CmmAlertModalModel } from 'src/app/common/data/dialogs/models/dialogs.model';
import { spinner } from 'src/app/common/data/utils/reducer/utils.selector';
import { Event, NavigationEnd, NavigationError, Router } from '@angular/router';

@Component({
  selector: 'pag-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit {
  /**
   * Nombre del environment
   */
  env: string = environment.CC_ENV_NAME;

  /**
   * Versión del proyecto
   */
  version: string = environment.CC_VERSION;

  /**
   * Desactiva la subscripción de observables
   */
  $unsubscribe = new Subject<void>();

  /**
   * Variable que contiene la version del proyecto
   */
  projectVersion: string = environment.CC_VERSION;

  /**
   * Variable que contiene el listado de opciones disponibles en el menu
   */
  menuList$: Observable<CmmSideNavModel[]>;
  menuList: Array<CmmSideNavModel> = [];

  /**
   * Variable que contiene el listado de opciones disponibles en el menu
   */
  actionsList$: Observable<CmmActionListModel[]>;

  /**
   * Indica si está abierto el diálogo de sesión expirada
   */
  expiratedSessionDialogOpen: boolean = false;

  /**
   * Altura del toolbar
   */
  toolbarHeight: number = 0;

  /**
   * Altura del controlador de versión
   */
  versionControlHeight: number = 0;

  /**
   * Variable que indica si se esta cargando algo
   */
  spinner: boolean = false;

  constructor(private store: Store, public dataservice: CmmDataService, public dialogService: CmmDialogService, public baseService: BaseService, private route: Router, public dialog: MatDialog, private timerSessionService: CmmTimerSessionService) {
    // Nos mantenemos atentos a los cambios de ruta
    this.subscribeRouter();

    // Observamos todos los cambios que haya en el estado del listado de modulos
    this.menuList$ = store.pipe(select(cmmSideNav));
    this.menuList$
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((sideNav: CmmSideNavModel[]) => {
        // Si hay algun elemento en el listado de modulos
        if (sideNav?.length) {
          // Guardo la lista de modulos para el sideNav a donde corresponde
          this.menuList = sideNav;
        }
      });

    // Observamos todos los cambios que haya en el estado del listdo de acciones
    this.actionsList$ = store.pipe(select(cmmActions));
    this.actionsList$
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((actionslist: CmmActionListModel[]) => {
        // Si no hay algun elemento en el listado de acciones
        if (!actionslist.length) {
          // Ejecuamos la funcion para solicitar las acciones
          this.getActionList();
        }
      });
  }

  //? Métodos de ciclo de vida

  ngOnInit(): void {
    // Llamo al contador para iniciar el primer contador
    this.timerSessionService.CmmInitiateFirstTimer();

    // Ejecutamos la funcion para observar el estatus de la session
    this.listenSessionExpiration();

    // Ejecutamos la funcion para observar el estatus del spinner
    this.listenSpinnerChanges();
  }

  ngAfterViewInit() {
    //* Obtengo la altura del toolbar
    this.toolbarHeight = document.getElementById('toolbar')?.offsetHeight as number;

    //* Obtengo la altura del controlador de versión
    this.versionControlHeight = document.getElementById('version_control')?.offsetHeight as number;
  }

  /**
   * Funcion para refrescar sesion
   */
  initialize() {
    // refresco el token
    this.baseService.getRefreshToken().subscribe();
  }

  /**
   * Funcion para obtener el listado de acciones
   */
  getActionList() {
    // obtengo el listado con todas las acciones
    this.baseService
      .getActionlist()
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe({
        next: (data: any) => {
          // Actualizo el estado del listado con la repuesta dada
          this.store.dispatch(new setActionList(data.data));
        },
        error: (err) => {},
      });
  }

  /**
   * Abre el diálogo para confirmar si se cierra la sesión o se mantiene
   */
  expiratedTimeDialog() {
    //*Si ya el diálogo está abierto, no lo vuelvo a abrir
    if (this.expiratedSessionDialogOpen) {
      return;
    }

    // Indicamos que se abrio el dialogo
    this.expiratedSessionDialogOpen = true;

    // Armamos la data de la alerta
    const messagesData: CmmAlertModalModel = {
      title: 'Su sesión está a punto de expirar',
      text: '',
      typeIcon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cerrar sesión',
      showConfirmButton: true,
      confirmButtonText: 'Mantener sesión',
      timeLeft: 30,
    };

    // Abrimos la alerta con el mensaje
    this.dialogService
      .CmmOpenCloseSessionDialog(messagesData)
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((result) => {
        // En caso de que el usuario confirma
        if (result == 'confirm') {
          // Indicamos que se cerro el dialogo
          this.expiratedSessionDialogOpen = false;

          // Refresco la seccion
          this.initialize();
        }

        // En cualquier otro caso, Si decide terminar la session
        else {
          // Indicamos que se cerro el dialogo
          this.expiratedSessionDialogOpen = false;

          // Ejecuto la funcion de cierre de session
          this.logout();
        }
      });
  }

  /**
   * Está pendiente de si la sesión expiró
   */
  listenSessionExpiration() {
    // Si estoy en auth, este diálogo no debe aparecer
    if (this.route.url.includes('auth')) {
      return;
    }

    // Hacemos la peticion a la api
    this.timerSessionService.sessionExpired
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((expirated) => {
        // En caso de que la session esta expirada y halla un token
        if (expirated && sessionStorage.getItem(authTokenVariable)) {
          // Abrimos el dialogo para extender la session o no
          this.expiratedTimeDialog();
        }

        // En cualquier otro caso, si tiene la session activa
        else {
          // Cerramos todos los dialogos que esten abiertos
          this.dialogService.CmmCloseAllDialogs();
        }
      });
  }

  /**
   * Escucha los cambios del spinner
   */
  listenSpinnerChanges() {
    // Observamos cada cambio en el estado del spinner
    this.store
      .select(spinner)
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe({
        next: (data: boolean) => {
          // Indicamos el estado del spinner
          this.spinner = data;
        },
      });
  }

  /**
   * Funcion para subscribirce a los cambios de ruta en la pagina
   */
  subscribeRouter() {
    // Nos suscribimos a los cambios de ruta
    this.route.events.subscribe((event: Event) => {
      // On NavigationStart
      if (event instanceof NavigationEnd) {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }

      // On NavigationError
      if (event instanceof NavigationError) {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  /**
   * Cierra la sesión
   */
  logout() {
    // Ejecuatamos el servicio para cerrar la session
    this.timerSessionService.CmmCloseSession();
  }

  ngOnDestroy() {
    // terminamos cualquier proceso que estuviera pendiente
    this.$unsubscribe.next();
  }
}
