import { Action } from '@ngrx/store';
import { DepositFilterModel, WithdrawFilterModel, ClientFilterModel, UserFtpModel, CardsFilterModel, AdministrationModel } from './module.models';

export enum ModulesActionsTypes {
  // módulo de retiro
  WithdrawFilter = '[Withdraw filter] Withdraw filter',
  ClearWithdrawFilter = '[Clear withdraw filter] Clear withdraw filter',

  // módulo de deposito
  DepositFilter = '[Deposit filter] Deposit filter',
  ClearDepositwFilter = '[Clear deposit filter] Clear deposit filter',

  // módulo de clientes inass
  ClientFilter = '[Client filter] Client filter',
  ClearClientFilter = '[Clear client filter] Clear client filter',

  // módulo de Cards
  CardsFilter = '[Cards filter] Cards filter',
  ClearCardsFilter = '[Clear Cards filter] Clear Cards filter',

  // módulo de servicios ftp
  UserFtpFilter = '[UserFtp filter] UserFtp filter',
  ClearUserFtpFilter = '[Clear userFtp filter] Clear userFtp filter',

  // módulo de servicios ftp
  AdministrationLayout = '[Administration Layout] Administration Layout',
  ClearAdministrationLayout = '[Clear Administration Layout] Clear Administration Layout',

  //reducer genereal
  ModulesFilterClear = '[Modules filterClear] Modules filterClear',
}

// Acciones del módulo de retiro
export class setWithdrawFilter implements Action {
  readonly type = ModulesActionsTypes.WithdrawFilter;

  constructor(public payload: { withdraw: WithdrawFilterModel }) {}
}
export class clearWithdrawFilter implements Action {
  readonly type = ModulesActionsTypes.ClearWithdrawFilter;

  constructor() {}
}

// Acciones del módulo de deposito
export class setDepositFilter implements Action {
  readonly type = ModulesActionsTypes.DepositFilter;

  constructor(public payload: { deposit: DepositFilterModel }) {}
}
export class clearDepositFilter implements Action {
  readonly type = ModulesActionsTypes.ClearDepositwFilter;

  constructor() {}
}

// Acciones del módulo de usuarios inass
export class setClientFilter implements Action {
  readonly type = ModulesActionsTypes.ClientFilter;

  constructor(public payload: { client: ClientFilterModel }) {}
}
export class clearClientFilter implements Action {
  readonly type = ModulesActionsTypes.ClearClientFilter;

  constructor() {}
}

// Acciones del módulo de cards
export class setCardsFilter implements Action {
  readonly type = ModulesActionsTypes.CardsFilter;

  constructor(public payload: { cards: CardsFilterModel }) {}
}
export class clearCardsFilter implements Action {
  readonly type = ModulesActionsTypes.ClearCardsFilter;

  constructor() {}
}

// Acciones del módulo de Integración bancaria
export class setUserFtpFilter implements Action {
  readonly type = ModulesActionsTypes.UserFtpFilter;

  constructor(public payload: { userFtp: UserFtpModel }) {}
}
export class clearUserFtpFilter implements Action {
  readonly type = ModulesActionsTypes.ClearUserFtpFilter;

  constructor() {}
}

// Acciones del módulo de Integración bancaria
export class setAdministrationLayout implements Action {
  readonly type = ModulesActionsTypes.AdministrationLayout;

  constructor(public payload: { administration: AdministrationModel }) {}
}
export class clearAdministrationLayout implements Action {
  readonly type = ModulesActionsTypes.ClearAdministrationLayout;

  constructor() {}
}

// Acciones del reducer general
export class modulesFilterClear implements Action {
  readonly type = ModulesActionsTypes.ModulesFilterClear;

  constructor() {}
}

export type ModulesActions = setWithdrawFilter | clearWithdrawFilter | setDepositFilter | clearDepositFilter | setClientFilter | clearClientFilter | setCardsFilter | clearCardsFilter | setUserFtpFilter | clearUserFtpFilter | setAdministrationLayout | clearAdministrationLayout | modulesFilterClear;
