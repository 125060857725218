import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EXTENDED_SPINNER } from 'src/app/common/data/utils/models/utils.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  /**
   * Variable que contiene la url del gateway
   */
  gatewayUrl: string;

  constructor(private http: HttpClient) {
    // Seteamos el la url base
    this.gatewayUrl = environment.INASS_CNE;
  }

  /**
   * @description   :Servicio para refrescar token
   * @returns token actualizado
   */
  getRefreshToken(): Observable<any> {
    return this.http.get(this.gatewayUrl + '/v1/profile/token/refresh');
  }

  /**
   * @description :Servicio para otener todo el listado de acciones de un projecto
   */
  getActionlist(): Observable<any> {
    //* Indica si debo añadir un número extra a la cuenta de requests terminados
    let dropDobleSpinner: boolean = false;

    //* Detecto si este endpoint ya fue llamado una vez (Al momento de hacer login)
    if (sessionStorage.getItem('alreadyCalled')) {
      dropDobleSpinner = false;
    } else {
      //* En caso de que sea la primera vez, uso la cuenta adicional y guardo el registro
      dropDobleSpinner = true;
      sessionStorage.setItem('alreadyCalled', 'true');
    }

    return this.http.get(this.gatewayUrl + '/v1/list/actions', {
      context: new HttpContext().set(EXTENDED_SPINNER, false),
    });
  }
}
