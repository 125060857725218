import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CmmActionListModel, CmmSideNavModel } from 'src/app/common/data/privileges/models/privileges.models';
import { cmmSideNav } from 'src/app/common/data/privileges/reducer/privileges.selectos';
import { CmmDataService } from 'src/app/common/services/data.service';

@Component({
  selector: 'cmp-base-sidenav',
  templateUrl: './base-sidenav.component.html',
  styleUrls: ['./base-sidenav.component.scss'],
})
export class BaseSidenavComponent {
  /**
   * Desactiva la subscripción de observables
   */
  $unsubscribe = new Subject<void>();

  /**
   * Variable que contiene el listado de opciones disponibles en el menu
   */
  menuList$: Observable<CmmSideNavModel[]>;
  menuList: Array<CmmSideNavModel> = [];

  constructor(private store: Store, public dataservice: CmmDataService, private route: Router) {
    // Observamos todos los cambios que haya en el estado del listado de modulos
    this.menuList$ = store.pipe(select(cmmSideNav));
    this.menuList$
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((sideNav: CmmSideNavModel[]) => {
        // Si hay algun elemento en el listado de modulos
        if (sideNav?.length) {
          // Guardo la lista de modulos para el sideNav a donde corresponde
          this.menuList = sideNav;
        }
      });
  }

  /**
   * Funcion para verificar el módulo que se indica es el que se esta viendo
   * @param page ruta del módulo
   */
  UrlCheck(page: any) {
    // Guardo en una variable la ruta actual
    let url = this.route.url;

    // En caso de que la primera parte de la ruta coincida retornamos true
    if (url.split('/')[1] == page.split('/')[1]) {
      return true;
    }

    // En cualquier otro caso, si no son iguales, retornamos false
    else {
      return false;
    }
  }
}
