import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { spinner } from 'src/app/common/data/utils/reducer/utils.selector';
import { banner, footer } from 'src/app/common/assets/images/images-routes';

@Component({
  selector: 'pag-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
})
export class AuthLayoutComponent {
  /**
   * Desactiva la subscripción de observables
   */
  $unsubscribe = new Subject<void>();

  /**
   * Variable que indica si se esta cargando algo
   */
  spinner: boolean = false;

  banner = banner;
  footer = footer;

  constructor(private store: Store) {}

  ngOnInit() {
    //* Me suscribo a los cambios del spinner
    this.listenSpinnerChanges();
  }

  /**
   * Escucha los cambios del spinner
   */
  listenSpinnerChanges() {
    // Observamos cada cambio en el estado del spinner
    this.store
      .select(spinner)
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe({
        next: (data: boolean) => {
          // Indicamos el estado del spinner
          this.spinner = data;
        },
      });
  }

  ngOnDestroy() {
    this.$unsubscribe.next();
  }
}
