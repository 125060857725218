<div *ngIf="!hideCover" class="row p-3 m-0 w-100 justify-content-center align-items-center card-border" [ngClass]="{ 'ccng-bgsecondary': coverType !== 'unavailable' }">
  <!-- Contenido principal del cover -->
  <div class="d-flex justify-content-center row" [ngSwitch]="coverType">
    <!-- Titulo y texto -->
    <div class="col-sm-12 mt-4 text-center cc-title-txt">
      <!-- Título -->
      <h5>{{ title }}</h5>

      <!-- Texto -->
      <p *ngIf="coverType !== 'noPrivilege'">{{ message }}</p>
    </div>

    <!-- Privilegio -->
    <span *ngIf="coverType == 'noPrivilege'" class="d-flex justify-content-center align-items-center mt-1">
      <!-- Título de privilegio -->
      <p *ngIf="privilege" class="m-0 p-0 cc-title-txt">Privilegio:</p>

      <!-- Contiene el nombre del privilegio que falta -->
      <mat-chip-listbox *ngIf="privilege">
        <mat-chip class="cc-bg-error cc-txt-white mx-3" selected>{{ getActionName(privilege) }}</mat-chip>
      </mat-chip-listbox>
    </span>
  </div>
</div>
