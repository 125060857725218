<div class="container-fluid vh-100 p-0">
  <!-- Banner horizontal -->
  <!-- <div class="banner w-100">
    <img [src]="banner" alt="Banner" />
  </div> -->

  <!-- Control de versión -->
  <!-- <cmm-cmp-version-control id="version_control"></cmm-cmp-version-control>

  <cmm-cmp-spinner class="vh-100" *ngIf="spinner"></cmm-cmp-spinner> -->

  <!-- Layout principal -->
  <div class="row vh-100 position-relative">
    <!-- Contenedor de la imagen -->
    <!-- <div class="d-none d-md-flex col-md-6 vh-100 p-0">
            Imagen
            <img [src]="boAuthCover" class="h-100 w-100" style="opacity: 0.7;">
        </div> -->

    <!-- Contenedor del componente -->
    <div class="col-md-12 d-flex">
      <div class="align-self-center text-center w-75 m-auto">
        <!-- Componente de la vista según la ruta -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <!-- <div class="footer w-100">
    <img [src]="footer" alt="footer" />
  </div> -->
</div>
