import { Component, Input } from '@angular/core';

@Component({
  selector: 'cmm-cmp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class CmmFooterComponent {
  /**
   * Logo de inass
   */
}
