import { API_ODOO, CC_FBO_IMAGES_URL, CC_NODE_ENV, GOOGLE_RECAPTCH } from './constants';

import version from 'package.json';

export const environment = {
  production: true,
  INASS_CNE: API_ODOO,
  CC_VERSION: version.version,
  CC_ENV_NAME: CC_NODE_ENV,
  CC_IMAGES_URL: CC_FBO_IMAGES_URL,
  siteKey: GOOGLE_RECAPTCH,
};
