// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CmmAuthGuard } from './common/guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

  {
    path: '',
    // canActivate: [CmmAuthGuard],
    loadChildren: () => import('./modules/base/base.module').then((m) => m.BaseModule),
  },

  // { path: '', canActivate:[AuthGuard], loadChildren: () => import('./modules/base/base.module').then(m => m.BaseModule) },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
