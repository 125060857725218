import { ModulesActions, ModulesActionsTypes } from './module.actions';
import { AdministrationModel, CardsFilterModel, ClientFilterModel, DepositFilterModel, UserFtpModel, WithdrawFilterModel } from './module.models';
export interface ModulesState {
  filters: {
    client: ClientFilterModel;
    withdraw: WithdrawFilterModel;
    deposit: DepositFilterModel;
    cards: CardsFilterModel;
    userFtp: UserFtpModel;
    administration: AdministrationModel;
  };
}

export const initialWidrawFilter: WithdrawFilterModel = {
  limit: '10',
  page: '0',
  search: '',
  startDate: '',
  endDate: '',
  amountStart: '',
  amountEnd: '',
  idWithdrawStatus: '',
  idWithdrawCurrency: '',
};

export const initialDepositFilter: DepositFilterModel = {
  limit: '10',
  page: '0',
  search: '',
  startDate: '',
  endDate: '',
  amountStart: '',
  amountEnd: '',
  idDepositStatus: '',
  idDepositCurrency: '',
};

export const initialClientFilter: ClientFilterModel = {
  limit: '10',
  page: '0',
  search: '',
  userName: '',
  idTypePerson: '',
  userLevel: '',
  idStatus: '',
  startDate: '',
  endDate: '',
};

export const initialCardsFilter: CardsFilterModel = {
  limit: '10',
  page: '0',
  search: '',
  idStatus: '',
  startDate: '',
  endDate: '',
};

export const initialUserFtpFilter: UserFtpModel = {
  limit: '10',
  page: '0',
  search: '',
  idDetailStatus: '',
};

export const initialAdministrationLayout: AdministrationModel = {
  currentPanel: 0,
  businessListStep: 1,
  ownerInfo: null,
  packageStep: 1,
  idPackage: '',
  moduleStep: 1,
  idModule: '',
};

export const initialModuleFilter: ModulesState = {
  filters: {
    withdraw: initialWidrawFilter,
    deposit: initialDepositFilter,
    client: initialClientFilter,
    cards: initialCardsFilter,
    userFtp: initialUserFtpFilter,
    administration: initialAdministrationLayout,
  },
};

export function ModulesReducer(state = initialModuleFilter, action: ModulesActions) {
  switch (action.type) {
    // Acciones de retiro
    case ModulesActionsTypes.WithdrawFilter:
      return {
        filters: {
          ...state.filters,
          withdraw: action.payload.withdraw,
        },
      };
    case ModulesActionsTypes.ClearWithdrawFilter:
      return {
        filters: {
          ...state.filters,
          withdraw: initialWidrawFilter,
        },
      };

    // Acciones de deposito
    case ModulesActionsTypes.DepositFilter:
      return {
        filters: {
          ...state.filters,
          deposit: action.payload.deposit,
        },
      };
    case ModulesActionsTypes.ClearDepositwFilter:
      return {
        filters: {
          ...state.filters,
          deposit: initialDepositFilter,
        },
      };

    // Acciones de busqueda de usuarios inass
    case ModulesActionsTypes.ClientFilter:
      return {
        filters: {
          ...state.filters,
          client: action.payload.client,
        },
      };
    case ModulesActionsTypes.ClearClientFilter:
      return {
        filters: {
          ...state.filters,
          client: initialClientFilter,
        },
      };

    // Acciones de busqueda de usuarios inass
    case ModulesActionsTypes.CardsFilter:
      return {
        filters: {
          ...state.filters,
          cards: action.payload.cards,
        },
      };
    case ModulesActionsTypes.ClearCardsFilter:
      return {
        filters: {
          ...state.filters,
          cards: initialClientFilter,
        },
      };

    // Acciones de busqueda de Integración bancaria
    case ModulesActionsTypes.UserFtpFilter:
      return {
        filters: {
          ...state.filters,
          userFtp: action.payload.userFtp,
        },
      };
    case ModulesActionsTypes.ClearUserFtpFilter:
      return {
        filters: {
          ...state.filters,
          userFtp: initialUserFtpFilter,
        },
      };

    // Acciones de definir cuales tablas ver en adminitration
    case ModulesActionsTypes.AdministrationLayout:
      return {
        filters: {
          ...state.filters,
          administration: action.payload.administration,
        },
      };
    case ModulesActionsTypes.ClearAdministrationLayout:
      return {
        filters: {
          ...state.filters,
          administration: initialAdministrationLayout,
        },
      };

    // Accion General
    case ModulesActionsTypes.ModulesFilterClear:
      return {
        filters: {
          deposit: initialDepositFilter,
          withdraw: initialWidrawFilter,
          client: initialClientFilter,
          cards: initialCardsFilter,
          userFtp: initialUserFtpFilter,
          administration: initialAdministrationLayout,
        },
      };
    default:
      return state;
  }
}
