import { NgModule } from '@angular/core';

import { BaseRoutingModule } from './base.routing.module';
import { BaseLayoutComponent } from './pages/base-layout.component';
import { CommonModule } from '@angular/common';
import { CmmModule } from 'src/app/common/common.module';
import { BaseHeaderComponent } from './components/base-header/base-header.component';
import { BaseSidenavComponent } from './components/base-sidenav/base-sidenav.component';

@NgModule({
  declarations: [
    BaseLayoutComponent,
    BaseHeaderComponent,
    BaseSidenavComponent
  ],
  imports: [
    CommonModule,
    BaseRoutingModule,
    CmmModule,
  ],
  exports: [
  ]
})

export class BaseModule {}
