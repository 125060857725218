<div class="d-flex flex-column justify-content-between h-100 w-100 p-3">
  <!-- Spinner progresivo con el tiempo que queda para mantener el diálogo -->
  <div *ngIf="timeAvailable != 0" class="position-absolute end-0 m-3 mt-0" style="height: 35px; width: 35px">
    <!-- Contenedor del spinner -->
    <div class="position-relative h-100 w-100">
      <!-- Componente de spinner -->
      <mat-progress-spinner class="h-100 w-100" style="transform: rotateY(180deg)" [value]="spinnerProgression" [strokeWidth]="10"> </mat-progress-spinner>

      <!-- Tiempo del spinner -->
      <p class="display-time">{{ timeAvailable }}</p>
    </div>
  </div>

  <!-- Imagenes por tipo de alerta -->
  <div [ngSwitch]="data.typeIcon" class="d-flex align-items-center justify-content-center"></div>

  <!-- Titulo -->
  <h5 matDialogTitle class="text-center mt-2 word-break cc-txt-bold">{{ data.title }}</h5>

  <!-- Contenedor principal del texto -->
  <mat-dialog-content>
    <!-- Texto del diálogo -->
    <p class="p-0 text-center">{{ data.text }}</p>
  </mat-dialog-content>

  <!-- Botones cancelar y aceptar-->
  <mat-dialog-actions class="row d-flex m-0" [ngClass]="{ 'justify-content-around': data.showCancelButton, 'justify-content-center': !data.showCancelButton }">
    <!-- Cancelar -->
    <cmm-cmp-b-button *ngIf="data.showCancelButton" class="col-12 col-md-5 my-2 my-md-0" [button_text]="data.cancelButtonText" [buttonType]="'warningOutline'" mat-dialog-close></cmm-cmp-b-button>

    <!-- Aprobar/Rechazar -->
    <cmm-cmp-b-button class="col-12 col-md-5 my-2 my-md-0" [button_text]="data.confirmButtonText" [buttonType]="'success'" matDialogClose="confirm"></cmm-cmp-b-button>
  </mat-dialog-actions>
</div>
