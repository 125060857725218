import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { userLoggedVariable } from 'src/app/common/data/constants/local-storage-variables';
import { CmmAlertModalModel } from 'src/app/common/data/dialogs/models/dialogs.model';
import { CmmSideNavModel } from 'src/app/common/data/privileges/models/privileges.models';
import { cmmSideNav } from 'src/app/common/data/privileges/reducer/privileges.selectos';
import { CmmTimerSessionService } from 'src/app/common/services/timer-session.service';
import { BaseService } from '../../services/base.service';
import { CmmDialogService } from 'src/app/common/services/dialogs.service';
import { CmmDataService } from 'src/app/common/services/data.service';

@Component({
  selector: 'cmp-base-header',
  templateUrl: './base-header.component.html',
  styleUrls: ['./base-header.component.scss'],
})
export class BaseHeaderComponent {
  /**
   * Desactiva la subscripción de observables
   */
  $unsubscribe = new Subject<void>();

  /**
   * IMAGENES
   */

  /**
   * Variable que contiene el listado de opciones disponibles en el menu
   */
  menuList$: Observable<CmmSideNavModel[]>;
  menuList: Array<CmmSideNavModel> = [];

  /**
   * Variable que tiene la info del usuario logeado para usar en el menu
   */
  userBackoffice: any;

  constructor(private store: Store, public dataservice: CmmDataService, public dialogService: CmmDialogService, public baseService: BaseService, private route: Router, private timerSessionService: CmmTimerSessionService) {
    // Observamos todos los cambios que haya en el estado del listado de modulos
    this.menuList$ = store.pipe(select(cmmSideNav));
    this.menuList$
      .pipe(
        // Indicamos que esta funcion se ejecutara hasta que el indique lo contario
        takeUntil(this.$unsubscribe)
      )
      .subscribe((sideNav: CmmSideNavModel[]) => {
        // Si hay algun elemento en el listado de modulos
        if (sideNav?.length) {
          // Guardo la lista de modulos para el sideNav a donde corresponde
          this.menuList = sideNav;
        }
      });
  }

  //? Métodos de ciclo de vida

  ngOnInit(): void {
    // Saco la info del usuario desde el ls para usar en mi objeto
    this.userBackoffice = JSON.parse(localStorage.getItem(userLoggedVariable)!);
  }

  /**
   * Funcion que redirige al usuario a la vista de detalle del usuario backoffice que seleccione
   */
  goBackofficeUserDetail() {
    // Llevamos al usurio a la vista de detalle de sus datos
    // this.route.navigate([`control-users/detail/inass/${this.userBackoffice.userName}`]);
  }

  /**
   * Funcion que abre el dialogo para cerrar sesion
   */
  openLogoutDialog() {
    // Armamos la data de la alerta
    const messagesData: CmmAlertModalModel = {
      title: 'Cerrar sesión',
      text: '¿Estás seguro que desea cerrar sesión?',
      giftData: '',
      typeIcon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
    };

    // Abrimos la alerta con el mensaje
    this.dialogService.CmmAlertModal(messagesData).subscribe({
      next: (result: string) => {
        // En caso de se comfirme
        if (result === 'confirm') {
          // Ejecutamos la funcion para terminar la session
          this.logout();
        }
      },
    });
  }

  /**
   * Cierra la sesión
   */
  logout() {
    // Ejecuatamos el servicio para cerrar la session
    this.timerSessionService.CmmCloseSession();
  }
}
