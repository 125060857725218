/**
 * Este archivo contiene las variables exportadas con cada ruta de las imagenes que se usen en common
 */

/**
 * COVERS
 */
export const background = '../app/common/assets/images/logos/adultos-mayores-login.jpg';

/**
 * DIALOGS
 */

/**
 * LOGOS
 */
export const inass = '../app/common/assets/images/logos/inass.jpg';
export const qr_amm = '../app/common/assets/images/logos/gaceta-ley-amm.png';
export const banner = '../app/common/assets/images/logos/banner.jpg';
export const footer = '../app/common/assets/images/logos/footer.jpg';
export const check = '../app/common/assets/images/logos/check.jpeg';

/**
 * ICONS
 */
