<mat-toolbar class="p-0" id="toolbar">
  <div class="cc-bg-navbar w-100 h-100 d-flex justify-content-between">
    <!-- Menu Expandible -->
    <mat-menu #beforeMenu="matMenu" xPosition="before">
      <!-- Info User -->
      <div class="col-8 mx-auto mt-3" style="max-width: 200px">
        <p class="m-0 text-center fs-16 cc-txt-bold cc-txt">@{{ userBackoffice.userName }}</p>
      </div>

      <!-- Divider -->
      <div class="d-flex m-0 col-12 justify-content-center">
        <p class="w-auto m-0 px-3">______________________________</p>
      </div>

      <!-- Menu options -->
      <div class="my-2">
        <!-- Enlaces de a los modulos del usuario -->
        <a *ngFor="let item of menuList" [href]="item.page" class="d-block d-md-none cursor-pointer cc-txt-a cc-txt base-button d-flex col-9 justify-content-start align-items-center mx-auto p-0 my-3">
          <mat-icon class="col-2 m-0 p-0 text-center cursor-pointer">{{ item.icon }}</mat-icon>
          <p class="m-0 p-0 ps-2">{{ item.moduleName }}</p>
        </a>

        <!-- Cerrar sesion -->
        <button class="cc-txt cc-bg-white border-0 d-flex col-9 justify-content-start align-items-center mx-auto p-0" (click)="openLogoutDialog()">
          <mat-icon class="col-2 m-0 p-0 text-center">exit_to_app</mat-icon>
          <p class="m-0 p-0 ps-2">Cerrar sesión</p>
        </button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
