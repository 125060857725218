<div *ngIf="documentTypeList" class="col-4 col-md-3 pe-1">
  <cmm-cmp-i-select
    formControlName="typeDocument"
    [optionsList]="documentTypeList"
    [implementCustomInput]="true"
    placeholder="Ej: V"
    label="Doc."
    optionKey="documentTypeId"
    selectMode="autocomplete"
    optionName="documentTypeName"
    >
    </cmm-cmp-i-select>
</div>

<div class="col-8 col-md-7">
  <cmm-cmp-i-text
    formControlName="documentNumber"
    label="Nro. de documento"
    placeholder="Ej: 12345678"
    [minLength]="6"
    [maxLength]="10"
    [onlyNumber]="true"
    [implementCustomInput]="true"
    >
    </cmm-cmp-i-text>
</div>
