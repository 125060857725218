<mat-list role="list" class="col-12 flex-column justify-content-start h-100 m-0 p-0" *ngIf="menuList.length > 0">

  <!-- Contenedor de icono del modulo -->
  <mat-list-item role="listitem" *ngFor="let item of menuList" [routerLink]="item.page" class="cursor-pointer" routerLinkActive="active">

      <!-- Icono del modulo -->
      <mat-icon *ngIf="item.icon" matTooltipPosition="after" [matTooltip]="item.moduleName" [ngClass]="UrlCheck(item.page) ? 'cc-txt-primary' : 'cc-txt'" class="mt-2 p-0 text-center cursor-pointer">{{item.icon}}</mat-icon>

  </mat-list-item>

</mat-list>
