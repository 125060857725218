import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthLayoutComponent } from './pages/auth-layout/auth-layout.component';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { AuthLoginFormComponent } from './components/auth-login-form/auth-login-form.component';
import { CmmModule } from 'src/app/common/common.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

@NgModule({
  declarations: [AuthLayoutComponent, AuthLoginFormComponent],
  imports: [CommonModule, AuthRoutingModule, CmmModule, RecaptchaV3Module],
  providers: [
    AuthService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey,
    },
  ],
})
export class AuthModule {}
