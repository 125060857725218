<footer class="footer overflow-hidden">
  <div class="container-fluid my-md-2 p-0">
    <!-- Linea -->
    <hr class="m-0" />
    <!-- Texto del footer -->
    <div class="row my-2 input-size">
      <div class="col-12 col-sm-4 d-none d-sm-flex align-items-center">
        <!-- Versión del proyecto -->
        <p class="col-12 text-center text-uppercase m-0 footer_text">version: {{ envVersion }}</p>
      </div>
      <!-- Texto informativo de la empresa -->
      <div class="d-flex col-12 col-sm-4 flex-column">
        <p class="col-12 text-center mb-0 footer_text">Soluciones Financieras inass, C. A.</p>
        <p class="col-12 text-center m-0 rif_text">R.I.F. J-413198282</p>
      </div>
      <div class="col-12 col-sm-4"></div>
    </div>
  </div>
</footer>
