<!-- Botón en estado normal, muestra texto o ícono con texto -->
<button *ngIf="!showSpinner" [disabled]="buttonDisabled" type="{{type}}" mat-button class="w-100 ccng-bold" [ngClass]="{'cc-btn-error': buttonType == 'cancel', 'cc-btn-primary': buttonType == 'success', 'cc-btn-warning': buttonType == 'warning', 'cc-btn-error-outline': buttonType == 'cancelOutline', 'cc-btn-primary-outline': buttonType == 'successOutline', 'cc-btn-warning-outline': buttonType == 'warningOutline'}"
    (keydown.enter)="$event.preventDefault()" (click)="submit.emit()" style="height: 45px;">

    <!-- Contenido del botón -->
    <span class="fs-16 d-flex align-items-center">

      <!-- En este espacio se pueden incluir íconos, imágenes o cualquier elemento, realmente -->
      <ng-content>
      </ng-content>

      <!-- Texto del botón -->
        {{ button_text }}

    </span>

</button>

<!-- Botón con spinner -->
<button *ngIf="showSpinner" type="{{type}}" mat-button class="ccng-bold w-100" [ngClass]="{'cc-btn-error': buttonType == 'cancel', 'cc-btn-primary': buttonType == 'success', 'cc-btn-warning': buttonType == 'warning', 'cc-btn-error-outline': buttonType == 'cancelOutline', 'cc-btn-primary-outline': buttonType == 'successOutline', 'cc-btn-warning-outline': buttonType == 'warningOutline'}"
    buttonType="button" disabled style="height: 45px;">

    <!-- Elemento que actúa de spinner -->
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

</button>
