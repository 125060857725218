// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './pages/base-layout.component';
import { CmmAuthGuard } from 'src/app/common/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    // canActivate: [CmmAuthGuard],
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BaseRoutingModule {}
