<!-- Versión nueva del input -->
<div *ngIf="implementCustomInput" class="w-100">

  <mat-form-field class="w-100 input-size px-0" [subscriptSizing]="subscriptSizing" appearance="outline" [ngClass]="selectMode == 'dialog'?'cursor-pointer':''" (click)="selectMode == 'dialog' && openSelectDialog()">

    <!-- Label -->
    <mat-label>{{label}}</mat-label>

    <!-- *Si es autocompletado -->

    <div *ngIf="selectMode == 'autocomplete'" class="w-100 h-100">

      <div class="d-flex justify-content-between align-items-center px-0 position-relative">

        <!-- Imagen final mostrada en la opción escogida -->
        <img *ngIf="choosenImageRoute && displayImages" class="ms-1" [ngStyle]="{'width.px': imgDimentions.width, 'height.px': imgDimentions.height, 'max-height': '24px'}" [src]="choosenImageRoute" alt="">

        <!-- Input de autocompletado -->
        <input type="text"
        class="autocomplete_input"
        inputmode="search"
        [placeholder]="placeholder"
        cmm-di-number-format
        [onlyNumber]="onlyNumber"
        [readonly]="readOnly"
        [minlength]="minLength"
        [required]="required"
        [disabled]="disabled"
        [maxlength]="maxLength"
        [(ngModel)]="currentValue"
        matInput
        [errorStateMatcher]="errorMatcher()"
        [matAutocomplete]="auto"
        #myAutocompleteInput
        (blur)="onTouch()"
        (input)="filterCurrentValue()"
        >

        <!-- Botón de eliminar selección -->
        <mat-icon *ngIf="choosenSearchKeyValue && displayClearSelect" class="cursor-pointer me-2 fs-23" matIconSuffix (click)="clearSelected()">clear</mat-icon>

        <!-- Flechita que indica que es un input de selección -->
        <mat-icon *ngIf="!choosenSearchKeyValue" matIconSuffix class="cursor-pointer me-2 fs-23">arrow_drop_down</mat-icon>

      </div>

      <!-- Contenedor de opciones de selección -->
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (closed)="onTouch()" [hideSingleSelectionIndicator]="true" (optionSelected)="selectAutocomplete($event)">

        <!-- Cada una de las opciones -->
        <mat-option class="p-0 px-3 option_bg hiddeScrollBar" [ngClass]="showOptionsBorder?'border-bottom':''" *ngFor="let option of filteredList" [value]="option[optionName]">

          <div class="d-flex justify-content-between align-items-center px-0">

            <!-- Imagen del campo -->
            <img *ngIf="displayImages" class="me-1" [ngStyle]="{'width.px': imgDimentions.width, 'height.px': imgDimentions.height}" [src]="imagesRoute + option[imageSearchKey].toLowerCase() + '.svg'" alt="">

            <!-- Texto de opción -->
            <!-- <p class="m-0">{{siblingOptionValue? option[siblingOptionValue] + ' | ' + option[optionValue]: option[optionValue]}}</p> -->

            <p class="m-0">{{option[optionName]}}</p>

          </div>

        </mat-option>

      </mat-autocomplete>

    </div>

    <!-- ///////////////////////////////////////////////////////////////////////////////  -->

    <!-- *Si es solo input de selección -->

    <mat-select *ngIf="selectMode == 'select'" class="p-0 ccng-bgsecondary h-100" [hideSingleSelectionIndicator]="true" [errorStateMatcher]="errorMatcher()" [placeholder]="placeholder" [required]="required" [disabled]="disabled" [(ngModel)]="valueSelected" (blur)="onTouch()">

      <!-- Cada una de las opciones -->
      <mat-option class="p-0 px-2 option_bg hiddeScrollBar" [ngClass]="showOptionsBorder?'border-bottom':''" *ngFor="let option of optionsList" [value]="option[optionName]" (onSelectionChange)="selectValue($event)">

        <div class="d-flex justify-content-between align-items-center px-3">

          <!-- Imagen del campo -->
          <img *ngIf="displayImages" class="me-1" [ngStyle]="{'width.px': imgDimentions.width, 'height.px': imgDimentions.height,  'max-height': '24px'}" [src]="imagesRoute + option[imageSearchKey].toLowerCase() + '.svg'" alt="">

          <!-- Texto de opción -->
          <p class="m-0">{{siblingOptionValue? option[siblingOptionValue] + ' | ' + option[optionName]: option[optionName]}}</p>

        </div>

      </mat-option>

    </mat-select>

    <!-- ///////////////////////////////////////////////////////////////////////////////  -->

    <!-- *Si es selección con diálogo de búsqueda -->

    <div *ngIf="selectMode == 'dialog'" class="d-flex w-100 h-100 justify-content-between align-items-center px-0 position-relative">

      <!-- Imagen de la opción seleccionada -->
      <img *ngIf="displayImages && choosenImageRoute" matPrefix class="h-100 ms-3" [ngStyle]="{'width.px': imgDimentions.width, 'height.px': imgDimentions.height, 'max-height': '24px'}" [src]="choosenImageRoute" alt="">

      <!-- Input de autocompletado -->
      <input type="text"
      class="autocomplete_input"
      [placeholder]="placeholder"
      cmm-di-number-format
      [onlyNumber]="onlyNumber"
      [readonly]="readOnly"
      [minlength]="minLength"
      [required]="required"
      [disabled]="disabled"
      [maxlength]="maxLength"
      [(ngModel)]="valueSelected"
      matInput
      [errorStateMatcher]="errorMatcher()"
      >

      <!-- Flechita que indica que es un input de selección -->
      <mat-icon matIconSuffix class="cursor-pointer me-2 fs-23">arrow_drop_down</mat-icon>

    </div>

    <!-- Mensajes de error -->

    <!-- Errores del required -->
    <mat-error *ngIf="control?.errors?.['required']">
      {{CmmErrorMessagesObject.required}}
    </mat-error>

    <!-- Errores del valor incorrecto -->
    <mat-error *ngIf="control?.errors?.['incorrectOption'] && !control?.errors?.['required']">
      <!-- Puedo poner un mensaje personalizado usando incorrectPattern, o sino, uso el mensaje estándar -->
      {{replaceMessage? CmmErrorMessagesObject.incorrectPattern: CmmErrorMessagesObject.incorrectOption}}
    </mat-error>

    <!-- Mensaje de error del API -->
    <mat-error *ngIf="control?.errors?.['apiError']">
      {{ control.errors?.['message']}}
    </mat-error>

  </mat-form-field>

</div>
