<div class="vh-100">

    <!-- Spinner global de base -->
    <cmm-cmp-spinner class="vh-100" *ngIf="spinner"></cmm-cmp-spinner>
    
    <!-- Control de versión -->
    <cmm-cmp-version-control id="version_control"></cmm-cmp-version-control>
    
    <!-- Navbar -->
    <cmp-base-header></cmp-base-header>

    <!-- Container -->
    <mat-sidenav-container [ngStyle]="{'height': 'calc(100% - ' + (toolbarHeight + versionControlHeight) + 'px)'}">
       
        <!-- SideNav Menu List -->
        <mat-sidenav class="d-none d-md-block" mode="side" [opened]="menuList.length > 0">
            
            <!-- Listado de modulos -->
            <cmp-base-sidenav></cmp-base-sidenav>

        </mat-sidenav>

        <!-- Container and Footer -->
        <mat-sidenav-content>
            
            <!-- Contenedor principal -->
            <div class="overflow-hidden pb-2" style="min-height: 87vh;">
                
                <!-- Aquí se muestran las diferentes páginas de base -->
                <router-outlet></router-outlet>

            </div>

            <!-- Footer -->
            <cmm-cmp-footer-version [envVersion]="version" style="height: 2.5rem;"></cmm-cmp-footer-version>
            
        </mat-sidenav-content>

    </mat-sidenav-container>

</div>