<div class="row g-0 version_control align-items-center" [ngClass]="bgClass">

  <div class="col-4 h-100 d-flex justify-content-center">

    <!-- Botón para cambiar APIS a modo mockup (Por ahora no hay funcionalidad de eso)
    <button class="cc-txt-white bold cursor-pointer h-100 p-3 d-none d-sm-flex" [matTooltip]="mockupModeActive?
    'Desactivar el modo mockup en todas las APIs':'Activar el modo mockup en todas las APIs'" mat-button (click)="toggleMockupMode()">
        {{mockupModeActive?'Desactivar':'Activar'}} modo mockup
    </button>

    <mat-icon class="d-block d-sm-none cc-txt-white cursor-pointer" matTooltip="Activa/desactiva el modo mockup en todas las APIs">swap_vert</mat-icon> -->

  </div>

  <div class="col-4 d-flex justify-content-center align-items-center h-100">

    <strong class="cc-txt-white bold text-center">{{ env }} Versión: {{version}}</strong>

  </div>

  <div class="col-4 h-100 d-flex justify-content-center align-items-center">

    <!-- Botón para cerrar sesión rapidamente -->
    <button class="cc-txt-white bold cursor-pointer h-100 p-3 d-none d-sm-flex" matTooltip="Hace un cierre de sesión forzado" mat-button (click)="this.timerSession.CmmCloseSession()">
      Resetear variables
    </button>

    <mat-icon class="d-block d-sm-none cc-txt-white cursor-pointer" matTooltip="Hace un cierre de sesión forzado" (click)="this.timerSession.CmmCloseSession()">delete</mat-icon>

  </div>

</div>
